import { NgModule } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { StyleClassModule } from 'primeng/styleclass';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PaginatorModule } from 'primeng/paginator';
import { ImageModule } from 'primeng/image';
import { CardModule } from 'primeng/card';
import { ScrollTopModule } from 'primeng/scrolltop';
import { DataViewModule } from 'primeng/dataview';
import { ChartModule } from 'primeng/chart';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarouselModule } from 'primeng/carousel';
import { TreeTableModule } from 'primeng/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { AccordionModule } from 'primeng/accordion';
import { EditorModule } from 'primeng/editor';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { DatePickerModule } from 'primeng/datepicker';
import { SelectModule } from 'primeng/select';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    TooltipModule,
    AccordionModule,
    CheckboxModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    SidebarModule,
    AvatarModule,
    StyleClassModule,
    RippleModule,
    BadgeModule,
    BreadcrumbModule,
    DividerModule,
    IconFieldModule,
    InputIconModule,
    KeyFilterModule,
    DropdownModule,
    ToastModule,
    ConfirmDialogModule,
    FileUploadModule,
    TableModule,
    InputSwitchModule,
    PaginatorModule,
    DividerModule,
    DatePickerModule,
    ImageModule,
    CardModule,
    ScrollTopModule,
    DataViewModule,
    ChartModule,
    InputMaskModule,
    MenuModule,
    SelectButtonModule,
    MultiSelectModule,
    CarouselModule,
    TreeTableModule,
    CheckboxModule,
    AutoCompleteModule,
    InputGroupModule,
    InputGroupAddonModule,
    EditorModule,
    ProgressBarModule,
    InputNumberModule,
    ToggleSwitchModule,
    SelectModule,
  ],
})
export class PrimengModule {}
